export function snackEmoji(name: string) {
  switch (name.toLowerCase()) {
    case 'lingerie':
      return ''
    case 'acessorios':
      return ''
    case 'cosmeticos':
      return ''
    case 'brincadeiras':
      return ''
    default:
      return ''
  }
}
